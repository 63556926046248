import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import InnerPageHero from "../components/inner-page-hero/inner-page-hero"
import MatchPoint from "../components/match-point/match-point"
import TabbedContent from "../components/tabbed-content/tabbled-content"

export default function Statistics({data}) {

    //Statistics Page data
    const statsPage = data?.wpPage?.statistics

    //Inner Hero Data
    const innerHero = statsPage?.statsInnerHero

    //Match Fixtures and Point Table Data
    const matchFixtures = data?.allWpFixture?.nodes
    const pointTab = data?.wp?.siteSettings?.siteSettings?.pointTable

    // Tabbed Content Data
    const tabbedContent = statsPage?.statsTabbedContent


    return (
        <>
        <DefaultLayout data={data}>
        <InnerPageHero innerData={innerHero} />
        <MatchPoint matchFixtures={matchFixtures} pointTab={pointTab} />
        {/* <TabbedContent charityIntroData={tabbedContent} /> */}
        </DefaultLayout>
        </>
    )
}

export const query = graphql`
  query($slug: String!) {
    wp {
        siteSettings {
          siteSettings {
            pointTable {
              column1Heading
              column2Heading
              column3Heading
              column4Heading
              backgroundImage {
                localFile {
                  childImageSharp {
                    fluid(fit: COVER, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              fixtureCtaTitle
              leftCtaLink {
                title
                url
              }
              rightCtaLink {
                title
                url
              }
              teamPoints {
                lossNumber
                point
                team
                winNumber
              }
            }
          }
        }
    }
    allWpFixture {
        nodes {
          slug
          title
          uri
          date(formatString: "DD MMMM, YYYY")
          fixtures {
            fixtureDate
            stadium
            awayTeam
            homeTeam
            time
          }
        }
    }
    wpPage(slug: { eq: $slug }) {
      statistics {
            statsInnerHero {
                backgroundImage {
                localFile {
                    childImageSharp {
                    gatsbyImageData(width: 1920, height: 380)
                    }
                }
                }
                leftButton {
                title
                url
                }
                rightButton {
                url
                title
                }
                title
            }
            statsTabbedContent {
                firstTabHeading
                firstTabDescription
                secondTabHeading
                secondTabDescription
                thirdTabHeading
                thirdTabDescription
                imageSignature {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 62, width: 161)
                    }
                  }
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 534, width: 302)
                    }
                  }
                }
                nameClubMember
                positionInClub
            }
        }
    }
  }
`
