import React from "react"
import { Link } from "gatsby"
import "../../fonts/fontawesome"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"
import BackgroundImage from 'gatsby-background-image'

import "./match-point.scss"
export default function MatchPoint({ matchFixtures, pointTab }) {

    //Image Data
    const imageData = pointTab?.backgroundImage?.localFile?.childImageSharp?.fluid

    //Get Future Match Fixtures data
    const currentDate = new Date()

    //Filter out the Future Matches data for Match Table. Convert fixtureDate format to JS Date format
    const futureData = matchFixtures.filter(match => new Date((match?.fixtures?.fixtureDate).replace(/(\d+[/])(\d+[/])/, '$2$1')) > currentDate)

    //Sort the fixtureDate to display on the Match Table from the closest future date to furthest date
    const futureMatches = futureData.sort((a, b) => new Date((a.fixtures?.fixtureDate).replace(/(\d+[/])(\d+[/])/, '$2$1')) - new Date((b?.fixtures?.fixtureDate).replace(/(\d+[/])(\d+[/])/, '$2$1')))

    return (
        <>
            {/*  <!-- All news area Start Here--> */}
            <div className="all-news-area sec-spacer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-8 match-view-tite">
                                    <h3 className="title-bg">Upcomming Fixtures</h3>
                                </div>
                                <div className="col-4 text-right match-view-more">
                                    {/* <Link className="view-more" to={`${pointTab?.leftCtaLink?.url}`}>{pointTab?.leftCtaLink?.title} <FontAwesomeIcon className="" icon={faAngleDoubleRight} style={{ width: "15px", height: "15px" }} /></Link> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">

                                    {!futureMatches.length ? (
                                        <p>Sorry no upcoming matches. The league is yet to start!</p>
                                    ) : (

                                        <BackgroundImage
                                            Tag="div"
                                            fluid={imageData}
                                            className="match-list mmb-45"
                                        >
                                            <div className="overly-bg"></div>
                                            <table className="match-table">
                                                <tbody>
                                                    {futureMatches?.slice(0, 10)?.map(match => {
                                                        return (
                                                            <tr>
                                                                <td className="medium-font">{match?.fixtures?.homeTeam}</td>
                                                                <td className="big-font">VS</td>
                                                                <td className="medium-font">{match?.fixtures?.awayTeam}</td>
                                                                <td>{match?.fixtures?.fixtureDate}, {match?.fixtures?.time}</td>
                                                                <td>{match?.fixtures?.venue}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </BackgroundImage>

                                    )}

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <h3 className="title-bg">League Table</h3>
                            <div className="point-list text-center">
                                <table className="point-table">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td className="country-name">{pointTab?.column1Heading}</td>
                                            <td>{pointTab?.column2Heading}</td>
                                            <td>{pointTab?.column3Heading}</td>
                                            <td>{pointTab?.column4Heading}</td>
                                        </tr>
                                        {/*  <!-- Dynamically sort thru the Point Table array from team with largest point to lowest--> */}
                                        {pointTab?.teamPoints?.sort((a, b) => b.point - a.point).map(el => {
                                            return (
                                                <tr>
                                                    <td>{pointTab?.teamPoints?.indexOf(el) + 1}</td>
                                                    <td className="country-name">{el?.team}</td>
                                                    <td>{el?.winNumber ? el?.winNumber : 0}</td>
                                                    <td>{el?.lossNumber ? el?.lossNumber : 0}</td>
                                                    <td>{el?.point ? el?.point : 0}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <Link className="view-more" to={`${pointTab?.rightCtaLink?.url}`}>{pointTab?.rightCtaLink?.title} {/* <i className="fa fa-angle-double-right" aria-hidden="true"></i> */}<FontAwesomeIcon className="" icon={faAngleDoubleRight} style={{ width: "15px", height: "15px" }} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- All news area end Here-->   */}
        </>
    )
}